import { Icons } from "@/components/icons";
import { Tooltip } from "@mui/material";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";

export const BLUR_FADE_DELAY = 0.15;

export const socialLinks = {
  twitter: "https://x.com/fugro",
  discord: "",
  github: "",
  instagram: "https://www.instagram.com/fugro",
  youtube: "https://www.youtube.com/fugro",
};

export const siteConfig = {
  name: "Geo-data Intelligence",
  description: "Transform Your Geo-Data Into Valuable Insights",
  url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.NEXT_PUBLIC_APP_URL!,
  keywords: ["SaaS", "Template", "Next.js", "React", "Tailwind CSS"],
  links: {
    email: "gdi.support@fugro.com",
    sales: "gdi.sales@fugro.com"
  },
  header: [
    {
      trigger: "Features",
      content: {
        main: {
          icon: <Icons.logo className="h-6 w-6" />,
          title: "Transforming Geo-Data",
          description: "Improve your geotechnical workflow with instant data digitization, management, visualization and analysis.",
          href: "/geo-data-intelligence",
        },
        items: [
          {
            href: "/geo-data-intelligence",
            title: "Data Digitisation",
            description: "Digitise all data reports",
          },
          {
            href: "/geo-data-intelligence",
            title: "Data Management",
            description: "Manage your data with ease",
          },
          {
            href: "/geo-data-intelligence",
            title: "Data Interpretation",
            description: "Analyse and calculate with your data",
          },
        ],
      },
    },
    {
      href: "/enterprise",
      label: (
          <span>Enterprise</span>
      ),
    },
    {
      href: "#",
      label: (
        <Tooltip style={{ zIndex: '1000' }} title="Coming soon!">
          <span>Pricing</span>
        </Tooltip>
      ),
    },
  ],
  pricing: [
    {
      name: "BASIC",
      href: "#",
      price: "$19",
      period: "month",
      yearlyPrice: "$16",
      features: [
        "1 User",
        "5GB Storage",
        "Basic Support",
        "Limited API Access",
        "Standard Analytics",
      ],
      description: "Perfect for individuals and small projects",
      buttonText: "Subscribe",
      isPopular: false,
    },
    {
      name: "PRO",
      href: "#",
      price: "$49",
      period: "month",
      yearlyPrice: "$40",
      features: [
        "5 Users",
        "50GB Storage",
        "Priority Support",
        "Full API Access",
        "Advanced Analytics",
      ],
      description: "Ideal for growing businesses and teams",
      buttonText: "Subscribe",
      isPopular: true,
    },
    {
      name: "ENTERPRISE",
      href: "#",
      price: "$99",
      period: "month",
      yearlyPrice: "$82",
      features: [
        "Unlimited Users",
        "500GB Storage",
        "24/7 Premium Support",
        "Custom Integrations",
        "AI-Powered Insights",
      ],
      description: "For large-scale operations and high-volume users",
      buttonText: "Subscribe",
      isPopular: false,
    },
  ],
  faqsLanding: [
    {
      question: "How can Geo-data Intelligence make my work faster and easier?",
      answer: (
        <span>
          The Geo-data Intelligence platform streamlines the management, interpretation, and presentation of your geotechnical data. 
          You can upload reports and datasets in formats like PDF, AGS, or CSV, process and validate them, and manage all your data in one place. 
          Easily perform statistical analyses, create ground models, and utilize our comprehensive tools for deep and foundation calculations with just a few clicks.
          Our platform is designed to consolidate all geotechnical needs in one place. 
          It offers more than just data management; it enables detailed data analysis and calculations, supporting scalability to meet your growing needs.
        </span>
      ),
    },
    {
      question: "What types of data does the platform support?",
      answer: (
        <span>
          Our platform caters to all your geotechnical needs, including borehole and drilling data, coring, geological descriptions, in-situ tests (SPT and CPT), 
          and laboratory tests. We plan to support geophysical data soon.
        </span>
      ),
    },
    // {
    //   question: "What kind of support does the platform provide?",
    //   answer: (
    //     <span>
    //       We offer extensive support from initial setup to ongoing technical issues, customized to your project requirements.
    //     </span>
    //   ),
    // },
    {
      question: "How secure is my data with Geo-data Intelligence?",
      answer: (
        <span>
          Your data security is paramount. We store all data on AWS, employing state-of-the-art encryption and strict 
          privacy protocols to ensure your personal information and Geo-data remains confidential, GDPR compliant and protected.
          Trust us to safeguard your information, so you can innovate with confidence.
        </span>
      ),
    },
    {
      question: "Is the platform compatible with my current software?",
      answer: (
        <span>
          Absolutely. You can export data and reports in common formats like AGS and Excel, 
          compatible with CAD software such as AutoCAD and Civil3D. Feature export functionality such as DXF will be rolled out soon.
          In the near future, our platform will also support GeoJSON for integration with GIS software like QGIS and ArcGIS.
        </span>
      ),
    },
    {
      question: "How can the platform support my team?",
      answer: (
        <span>
          The platform enhances team efficiency and productivity by centralizing data storage and ensuring standardized data processing and analysis. 
          This unified approach prevents data duplication and streamlines your team’s workflows, allowing for quicker and more consistent project execution.
        </span>
      ),
    },
    {
      question: "How do I start using Geo-data Intelligence?",
      answer: (
        <span>
          Getting started is straightforward. Simply create an account, upload your data, and explore our features to start interpreting your data efficiently.
        </span>
      ),
    },
    // {
    //   question: "What makes this platform unique compared to other geotechnical data management tools?",
    //   answer: (
    //     <span>
    //       Our platform is designed to consolidate all geotechnical needs in one place. 
    //       It offers more than just data management; it enables detailed data analysis and calculations, supporting scalability to meet your growing needs.
    //     </span>
    //   ),
    // },
  ],
  faqsEnterprise: [
    {
      question: "What is Geo-data Intelligence Enterprise?",
      answer: (
        <span>
          Geo-data Intelligence Enterprise is a comprehensive platform designed to streamline the management, interpretation, and presentation of geotechnical data. 
          It is designed for enterprises that want to use the platform in their own company, automating processes
          and bringing all workspaces together. With the company-wide compliance, Enterprise is a solution that allows
          companies to ensure security across the organization and project stakeholders through access management
          and deployment configuration (Enterprise can be deployed either on cloud or on-premises data centers).
        </span>
      ),
    },
    {
      question: "Is there a difference between Geo-data Intelligence Business and Enterprise?",
      answer: (
        <span>
          Yes, there are distinctions between the Business and Enterprise versions of Geo-data Intelligence. While both versions offer robust geotechnical data management and analysis capabilities, the Enterprise version includes advanced features tailored for larger organizations. 
          These features may encompass enhanced data processing capabilities, additional analytical tools, increased storage capacity, and support for a broader range of data formats. 
          The Enterprise version is designed to meet the complex needs of large-scale projects and teams, providing a more comprehensive solution for geotechnical data management.
        </span>
      ),
    },
    {
      question: "How do I migrate to Enterprise?",
      answer: (
        <span>
          Migrating to Geo-data Intelligence Enterprise is a straightforward process. Organizations can upgrade by contacting our support team or account manager, who will guide them through the transition. 
          The migration process ensures that all existing data and configurations are seamlessly transferred to the Enterprise platform without any loss of information. 
          Additionally, our support team provides assistance during the migration to address any questions or concerns, ensuring a smooth transition to the 
          enhanced features and capabilities of the Enterprise version.
        </span>
      ),
    },
    {
      question: "How secure is my data with Geo-data Intelligence?",
      answer: (
        <span>
          Your data security is paramount. We store all data on AWS, employing state-of-the-art encryption and strict 
          privacy protocols to ensure your personal information and Geo-data remains confidential, GDPR compliant and protected.
          Trust us to safeguard your information, so you can innovate with confidence.
        </span>
      ),
    },
  ],
  faqs: [
    {
      question: "What is GDI?",
      answer: (
        <span>
          GDI is a platform that helps you build and manage your AI-powered
          applications. It provides tools and services to streamline the
          development and deployment of AI solutions.
        </span>
      ),
    },
    {
      question: "How can I get started with GDI?",
      answer: (
        <span>
          You can get started with GDI by signing up for an account on our
          website, creating a new project, and following our quick-start guide.
          We also offer tutorials and documentation to help you along the way.
        </span>
      ),
    },
    {
      question: "What types of AI models does gdi.fugro.com support?",
      answer: (
        <span>
          GDI supports a wide range of AI models, including but not limited
          to natural language processing, computer vision, and predictive
          analytics. We continuously update our platform to support the latest
          AI technologies.
        </span>
      ),
    },
    {
      question: "Is GDI suitable for any geotechnical engineer?",
      answer: (
        <span>
          Yes, GDI is designed to be user-friendly for both beginners and
          experienced developers. We offer intuitive interfaces, pre-built
          templates, and extensive learning resources to help users of all skill
          levels create AI-powered applications.
        </span>
      ),
    },
    {
      question: "What kind of support does GDI provide?",
      answer: (
        <span>
          GDI provides comprehensive support including documentation, video
          tutorials, a community forum, and dedicated customer support. We also
          offer premium support plans for enterprises with more complex needs.
        </span>
      ),
    },
  ],
  footer: [
    {
      title: "Product",
      links: [
        { href: "/geo-data-intelligence", text: "Features", icon: null },
        { href: "/enterprise", text: "Enterprise", icon: null },
        { href: "/#", text: "Pricing", icon: null },
      ],
    },
    {
      title: "Company",
      links: [
        { href: "#", text: "About Us", icon: null },
        { href: "#", text: "Careers", icon: null },
      ],
    },
    {
      title: "Resources",
      links: [
        { href: "mailto:gdi@fugro.com", text: "Contact", icon: null },
        { href: "mailto:gdi.support@fugro.com", text: "Support", icon: null },
        { href: "#", text: "Status", icon: null },
      ],
    },    
    {
      title: "Social",
      links: [
        {
          href: socialLinks.twitter,
          text: "Twitter",
          icon: <FaTwitter />,
        },
        {
          href: socialLinks.instagram,
          text: "Instagram",
          icon: <RiInstagramFill />,
        },
        {
          href: socialLinks.youtube,
          text: "Youtube",
          icon: <FaYoutube />,
        },
      ],
    },
  ],
};

export type SiteConfig = typeof siteConfig;
