import axios from 'axios';
import Logger from './logger';
import replaceBackendUrl from './replaceBackendUrl';
import Crypto from 'src/app/utils/crypto';

// Create the axios instance
const axiosHelper = axios.create();

/*use axios helper in client side*/
axiosHelper.interceptors.request.use(
  async (config) => {

    if (config.url && config.url.includes('api/proxy')) {
      const encryptRequest : boolean = process.env.ENCRYPT_REQUEST ? (process.env.ENCRYPT_REQUEST.toLowerCase() === 'true') : false; 
      // Extract everything after api/proxy2?url=
      const baseUrl = config.url.substring(0, config.url.indexOf('=')+1);
      // Everything after api/proxy2?url=
      let destination = config.url.split(baseUrl)[1];
      destination = (encryptRequest) ? Crypto.encrypt(destination, false) : destination;
      config.url = `/${baseUrl}${encodeURIComponent(destination)}`;
      return config;
    }
    
    //If the URL is api/proxy, the auth header will be added there, so just return
    //if (config.url && config.url.includes('api/proxy')) return config;

    //if(process.env.NODE_ENV === 'development' && config.url && config.url.includes('/backend'))
    if(config.url && config.url.includes('/backend'))
      config.url = replaceBackendUrl(config.url);

    //If the client added a config.headers.Authorization (maybe want to use api key instead of token), just return
    if (config.headers.Authorization) return config;
    try {
      const response = await axios.get('/api/auth/token');
      const token = response.data;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      Logger.error(`Error fetching the token: ${JSON.stringify(error)}`)
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle errors
axiosHelper.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //user does not have valid credentials
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      window.location.href = '/api/auth/login';
      return;
    }
    //User are using more than 1 browser
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      //alert('You have been logged out...');
      //window.location.href = '/api/auth/logout';
      window.dispatchEvent(new CustomEvent('user-logged-out'));      
      return;
    }

    return Promise.reject(error);
  },
);

export default axiosHelper;
